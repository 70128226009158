import { injectGlobal } from 'styled-components';

import CircularBook from './fonts/CircularStd-Book.woff';
import CircularBookItalic from './fonts/CircularStd-BookItalic.woff';
import CircularBold from './fonts/CircularStd-Bold.woff';
import CircularBoldItalic from './fonts/CircularStd-BoldItalic.woff';

import theme from './theme';

/* eslint-disable no-unused-expressions */
injectGlobal`
  @font-face {
    font-family: 'Circular Std';
    font-weight: normal;
    src: url(${CircularBook});
  }

  @font-face {
    font-family: 'Circular Std';
    font-weight: normal;
    font-style: italic;
    src: url(${CircularBookItalic});
  }

  @font-face {
    font-family: 'Circular Std';
    font-weight: bold;
    src: url(${CircularBold});
  }

  @font-face {
    font-family: 'Circular Std';
    font-weight: bold;
    font-style: italic;
    src: url(${CircularBoldItalic});
  }

  /* TODO better way to do this? */
  * {
    font-family: ${theme.fonts.sans};
    word-break: break-word;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  body {
    background: ${theme.colors.grayLight};
    margin: 0;
    padding: 0;
  }
`;
