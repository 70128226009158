import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import '../../styled/global';
import Header from './Header';
import Footer from './Footer';
import theme from '../../styled/theme';
import favicon from '../../images/favicon.png'

library.add(fab);

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            social {
              profile
              url
            }
          }
        }
        wordpressWpApiMenusMenusItems(slug: { eq: "main-menu" }) {
          items {
            object_id
            title
            url
            object
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[{ name: 'description', content: 'Cantante de merengue dominicano' }, { name: 'keywords', content: 'sample, something' }, { name: 'google-site-verification', content: 'pxGGU-faf6YrRcHhQr6Lr9rQBt-Ygw1B6KI8TrqNJBI' }]}
            link={[{ rel: 'shortcut icon', type: 'image/png', href: favicon }]}
          />
          <Header siteTitle={data.site.siteMetadata.title} menu={data.wordpressWpApiMenusMenusItems} location={location} />
          <div>
            {children}
          </div>
          <Footer siteTitle={data.site.siteMetadata.title} social={data.site.siteMetadata.social} />
        </>
      </ThemeProvider>
    )}
  />
);

// Layout.propTypes = {
//   children: PropTypes.element.isRequired,
// };

export default Layout;
