export default {
  colors: {
    primary: '#CA895F',
    black: '#060506',
    grayDark: '#252729',
    gray: '#9B9B9B',
    grayMid: '#DFDFDF',
    grayLight: '#EFEFEF',
    grayLighter: '#F7F7F7',
  },
  fonts: {
    sans: '"Circular Std", sans-serif',
    test: '"Comic Sans"',
  },
  textStyles: {
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
    },
  },
};
