import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Container } from 'styled-bootstrap-components';
import SocialMedia from '../Global/SocialMedia';
import theme from '../../styled/theme';

const FooterWrapper = styled.footer`
  background: ${theme.colors.black};
  margin-top: 4rem;
  padding: 4rem 0;
`;

const Footer = props => (
  <FooterWrapper bg="black">
    <Container color="white" textAlign="center">
      <SocialMedia profiles={props.social} color="primary" fontSize="1.5rem" justifyContent="center" />
      2018 &copy; {props.siteTitle}. Todos los derechos reservados.
    </Container>
  </FooterWrapper>
);

export default Footer;
