import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color, fontSize, margin, justifyContent } from 'styled-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import facebook from '@fortawesome/fontawesome-free-brands/faFacebookF';
import twitter from '@fortawesome/fontawesome-free-brands/faTwitter';
import instagram from '@fortawesome/fontawesome-free-brands/faInstagram';
import youtube from '@fortawesome/fontawesome-free-brands/faYoutube';
import spotify from '@fortawesome/fontawesome-free-brands/faSpotify';

const Wrapper = styled.ul`
  display: flex;
  list-style: none;
  padding: 0.5rem 0;
  margin: 0;
  ${fontSize};
  ${margin};
  ${justifyContent};

  li {
    margin: 0 0.5rem;

    a {
      ${color};
    }
  }
`;

const Icon = styled.li`
  background: ${props => props.color && props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
`;

const SocialMediaRegular = props => (
  <Wrapper color={props.color} fontSize={props.fontSize} justifyContent={props.justifyContent}>
    <li>
      <a href="https://www.instagram.com/Eddy_Herrera" target="_blank" rel="nofollow noopener">
        <FontAwesomeIcon icon={instagram} />
      </a>
    </li>
    <li>
      <a href="https://twitter.com/Eddy_Herrera" target="_blank" rel="nofollow noopener">
        <FontAwesomeIcon icon={twitter} />
      </a>
    </li>
    <li>
      <a href="https://www.facebook.com/EddyHerreraOficial" target="_blank" rel="nofollow noopener">
        <FontAwesomeIcon icon={facebook} />
      </a>
    </li>
    <li>
      <a href="https://www.youtube.com/user/EddyHerreraOficial" target="_blank" rel="nofollow noopener">
        <FontAwesomeIcon icon={youtube} />
      </a>
    </li>
    <li>
      <a
        href="https://open.spotify.com/artist/4yNdrUaF54csrIconxVTnqzC?si=GUpNjExYS-uY6R6-u_FhVA"
        target="_blank"
        rel="nofollow noopener"
      >
        <FontAwesomeIcon icon={spotify} />
      </a>
    </li>
  </Wrapper>
);

const SocialMediaCircled = props => {
  const { profiles } = props;
  return (
    <Wrapper color={props.color} fontSize={props.fontSize} justifyContent={props.justifyContent}>
      <Icon color="#e1306c">
        <a href="https://www.instagram.com/Eddy_Herrera" target="_blank" rel="nofollow noopener">
          <FontAwesomeIcon icon={instagram} color="white" />
        </a>
      </Icon>
      <Icon color="#1da1f2">
        <a href="https://twitter.com/Eddy_Herrera" target="_blank" rel="nofollow noopener">
          <FontAwesomeIcon icon={twitter} color="white" />
        </a>
      </Icon>
      <Icon color="#3b5998">
        <a href="https://www.facebook.com/EddyHerreraOficial" target="_blank" rel="nofollow noopener">
          <FontAwesomeIcon icon={facebook} color="white" />
        </a>
      </Icon>
      <Icon color="#ff0000">
        <a href="https://www.youtube.com/user/EddyHerreraOficial" target="_blank" rel="nofollow noopener">
          <FontAwesomeIcon icon={youtube} color="white" />
        </a>
      </Icon>
      <Icon color="#1db954">
        <a
          href="https://open.spotify.com/artist/4yNdrUaF54csrIconxVTnqzC?si=GUpNjExYS-uY6R6-u_FhVA"
          target="_blank"
          rel="nofollow noopener"
        >
          <FontAwesomeIcon icon={spotify} color="white" />
        </a>
      </Icon>
    </Wrapper>
  );
};

const SocialMedia = props => {
  const { profiles } = props;
  return <SocialMediaRegular {...props} />;
  // return <SocialMediaCircled />;
};

SocialMedia.propTypes = {
  color: PropTypes.string.isRequired,
};

export default SocialMedia;
