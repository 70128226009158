import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import { Container, Navbar, Nav, Button } from 'styled-bootstrap-components';
import SocialMedia from '../Global/SocialMedia';
import theme from '../../styled/theme';

const Logo = styled.div`
  color: white;
  font-size: 1.125rem;
  letter-spacing: 0.2em;
  padding: 0.5rem;
  text-transform: uppercase;

  &:hover {
    color: white;
  }
`;

const StyledNavbarLink = styled.li`
  padding: 0.5rem;
`;

const StyledLink = styled(Link)`
  color: white;
  font-size: 0.85rem;
  letter-spacing: 1px;
  text-transform: uppercase;

  ${({ active }) => active && css`
    color: ${theme.colors.primary};
  `}
`;

const StyledContainer = styled(Container)`
  @media (min-width: 767px) {
    display: flex;
  }
`;

class Header extends Component {
  state = {
    hidden: true,
  };

  toggle = () => this.setState({
    hidden: !this.state.hidden
  });

  getSlug(url) {
    const parts = url.split('/');
    return parts.length > 2 ? parts[parts.length - 2] : '';
  }

  render() {
    const menuItems = this.props.menu.items.map((item, index) => {

      const slug = this.getSlug(item.url);
      const currentSlug = this.props.location.pathname.replace('/', '');

      return (
        <StyledNavbarLink key={item.object_id}>
          <StyledLink to={`/${slug}`} color="white" active={slug === currentSlug ? 1 : 0}>
              {item.title}
          </StyledLink>
        </StyledNavbarLink>
      );
    });

    return (
      <Navbar expandMd p={25} bg="black">
        <StyledContainer>
          <Nav style={{ justifyContent: 'space-between' }}>
            <Link to="/">
              <Logo color="white" brand>EDDY HERRERA</Logo>
            </Link>
            <Button
              dark
              outline
              toggleCollapse
              expandMd
              onClick={this.toggle}
            >
              <span>&#9776;</span>
            </Button>
          </Nav>
          <Nav collapse expandMd hidden={this.state.hidden} end={1}>
            {menuItems}
            <SocialMedia color="primary" fontSize="1.125rem" />
          </Nav>
        </StyledContainer>
      </Navbar>
    );
  }
}

export default Header;
